
import moment from "moment";
import { defineComponent, ref, onMounted } from "vue";
// import Datatable from "@/components/kt-datatable/KTDatatable.vue";
// import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
// import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
// import StatisticsWidget5 from "@/components/widgets/statsistics/Widget5.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import autoreponders from "@/core/data/autoresponderkeywords";
import { IAutoresponder } from "@/core/data/autoresponderkeywords";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "customers-listing",
  components: {
    // Datatable,
    // ExportCustomerModal,
    // AddCustomerModal,
    // StatisticsWidget5
  },
  setup() {
    const store = useStore();
    const planList = ref([]);
    const addONplanList = ref([]);
    onMounted(() => {
      setCurrentPageBreadcrumbs("Buy Plan", []);
      getPlanList();
      getAddPlanList();
    });

    const getPlanList = () => {
      var request = {
        url: "/plan/user/plan",
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          // console.log('datata',tableData.value);
          planList.value = data.data;
        }
      });
    };

    const getAddPlanList = () => {
      var request = {
        url: "/plan/user/addons",
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          // console.log('datata',tableData.value);
          addONplanList.value = data.data;
          console.log("datata", addONplanList.value);
        }
      });
    };

    // const deleteFewAutoresonder = () => {
    //   checkedAutoresponderKeyword.value.forEach((item) => {
    //     deleteAutoresponderKeyword(item);
    //   });
    //   checkedAutoresponderKeyword.value.length = 0;
    // };

    // const deleteAutoresponderKeyword = (id) => {
    //   Swal.fire({
    //     title: "Are you sure?",
    //     text: "You won't be able to revert this!",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Yes, delete it!",
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       var request = {
    //         url: `keyword/${id}`,
    //       };
    //       store.dispatch(Actions.DELETE, request).then((data) => {
    //         showTable.value = false;
    //         Swal.fire("Deleted!", "Autoresponder keyword has been deleted.", "success");
    //         getItem();
    //       });

    //     }
    //   });
    // };

    // const search = ref<string>("");
    // const searchItems = () => {
    //   tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
    //   if (search.value !== "") {
    //     let results: Array<IAutoresponder> = [];
    //     for (let j = 0; j < tableData.value.length; j++) {
    //       if (searchingFunc(tableData.value[j], search.value)) {
    //         results.push(tableData.value[j]);
    //       }
    //     }
    //     tableData.value.splice(0, tableData.value.length, ...results);
    //   }
    // };

    // const searchingFunc = (obj, value): boolean => {
    //   for (let key in obj) {
    //     if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
    //       if (obj[key].indexOf(value) != -1) {
    //         return true;
    //       }
    //     }
    //   }
    //   return false;
    // };
    //  const dateTime = (value) => {
    //   return moment(value).format("YYYY-MM-DD");
    // };

    return {
      //   tableData,
      //   tableHeader,
      //   deleteFewAutoresonder,
      //   search,
      //   searchItems,
      //   checkedAutoresponderKeyword,
      //   deleteAutoresponderKeyword,
      //   dateTime,
      //   showTable,
      getPlanList,
      planList,
      addONplanList,
    };
  },
});
